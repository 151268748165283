@import 'antd/dist/antd.css';

.App {
  text-align: center;
}

.ant-layout {
  min-height: 100vh;

}

.question-text {
  height: 45vh;
  background-color: #fff;
  padding: 20px 0px;
  width: 100%;
  font-size: 25px;
  align-items: center;
}
h1.ant-typography{margin-bottom: 0px;}

.buttons-container {
  display: flex;
  width: 100%;
  flex-flow: row wrap;
  justify-content: center;
  gap: 15px;
}

button.ant-btn.ant-btn-dashed.answer {
  width: 30px;
}

@media only screen and (max-width: 600px) {
  .ant-layout-header {
    height: 130px;
  }

  .ant-layout-content {
    margin-top: 135px;
  }

  main.ant-layout-content.site-layout {
    padding: '0 25px';
  }

  .ant-card {
    width: 400px;
  }
}